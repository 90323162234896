(function ($) {
    'use strict';

    $(function () {
        if (typeof ($.validator) !== 'function') return;

        /**
         * @todo Testare le funzioni di validazione
         */
        function inRange(value, min, max) {
            return value >= min && value <= max;
        }


        $.validator.addMethod('rangefield', function (value, element, params) {
            var fieldName = element.getAttribute('name');
            var dataType = element.getAttribute('type');
            var rangeLower = params.rangelower;
            var rangeGreater = params.rangegreater;
            var lowerProp = document.querySelector('[name="' + rangeLower + '"]');
            var greaterProp = document.querySelector('[name="' + rangeGreater + '"]');

            if (typeof (rangeLower) === 'undefined' || rangeLower === null) {
                console.warn('Could not validate ' + fieldName + ' against null or undefined field');
                return true;
            }

            if (typeof (rangeGreater) === 'undefined' || rangeGreater === null) {
                console.warn('Could not validate ' + fieldName + ' against null or undefined field');
                return true;
            }

            if (!lowerProp) {
                console.warn('A field named ' + rangeLower + ' was not found: could not validate ' + fieldName);
                return true;
            }

            if (!greaterProp) {
                console.warn('A field named ' + rangeGreater + ' was not found: could not validate ' + fieldName);
                return true;
            }

            return inRange(element.value, lowerProp.value, greaterProp.value);

        });

        if ($.validator.unobtrusive) {
            $.validator.unobtrusive.adapters.add('rangefield', ['rangelower', 'rangegreater'], function (options) {
                options.rules["rangefield"] = {
                    rangelower: options.params.rangelower,
                    rangegreater: options.params.rangegreater
                };
                options.messages['rangefield'] = options.message;
            });
        }

    });
})(jQuery);