; (function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('AlertMessage', ['Hammer'], factory);
    } else {
        // Browser globals
        root.AlertMessage = factory(Hammer);
    }
}(typeof self !== 'undefined' ? self : this, function (Hammer) {
    'use strict';

    var DEFAULTS = {
        type: 'MESSAGE',
        id: null,
        autofade: false,
        dismissable: false,
        ondismiss: null
    }

    function AlertMessage(element, options) {
        this.defaults = {};
        this.container = element;
        this.hammer = (typeof (Hammer) !== 'undefined') ? new Hammer(element) : null;
        this.dismissleft = this.dismissleft.bind(this);
        this.dismissright = this.dismissright.bind(this);
        this.fade = this.fade.bind(this);
        this.ontransition = this.ontransition.bind(this);
        this.ondismiss = this.ondismiss.bind(this);
        this.setup = this.setup.bind(this);
        this.setup(options);
        this.container.addEventListener('transitionend', this.ontransition);

        if (this.defaults.autofade) {
            window.setTimeout(this.fade, this.defaults.autofade);
        }

        if (this.defaults.dismissable) {
            var button = this.container.querySelector('[data-dismiss]');
            button.addEventListener('click', this.fade);
        }
    }
    
    AlertMessage.prototype = {
        defaults: {},
        dismissleft: function () {
            this.container.classList.add('-dismissright');
            this.ondismiss()
        },
        dismissright: function () {
            this.container.classList.add('-dismissleft');
            this.ondismiss()
        },
        fade: function () {
            this.container.classList.add('-fade');
            this.ondismiss()
        },
        ondismiss: function () {
            if (typeof (this.__ondismissFn) === 'function') {
                this.__ondismissFn.apply(this, [this.defaults.type, this.container, this.defaults.id])
            }
        },
        ontransition: function (event) {
            if (event.target != this.container) return;
            if (this.defaults.autofade || this.defaults.dismissable) {
                this.container.removeEventListener('transitionend', this.ontransition);
                this.container.parentElement.removeChild(this.container);
            }
        },
        setup: function (options) {
            // read options
            for (var opt in DEFAULTS) {
                this.defaults[opt] = (typeof (this.defaults[opt]) !== 'undefined') ? this.defaults[opt] : DEFAULTS[opt];
                if (typeof (this.container.dataset[opt]) !== 'undefined') this.defaults[opt] = this.container.dataset[opt];
                if (options && typeof (options[opt]) !== 'undefined') this.defaults[opt] = options[opt];
            }

            this.defaults.autofade = isNaN(parseInt(this.defaults.autofade)) ? false : parseInt(this.defaults.autofade);
            this.defaults.dismissable = this.defaults.dismissable && (this.defaults.dismissable === "true" || this.defaults.dismissable === true);

            try {
                if (eval(this.defaults.ondismiss) && typeof (eval(this.defaults.ondismiss) === 'function')) {
                    this.__ondismissFn = eval(this.defaults.ondismiss)
                }
            } catch (err) {
                console.warn(err);
                this.defaults.ondismiss = null;
            }

            if (this.hammer && this.defaults.dismissable) {
                this.hammer.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
                this.hammer.on('swipeleft', this.dismissleft);
                this.hammer.on('swiperight', this.dismissright);
            }
        }
    }

    return AlertMessage;
}));