; (function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('ModalWindow', [], factory);
    } else {
        // Browser globals
        root.ModalWindow = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    'use strict';

    function ModalWindow(element, options) {
        this.container = element;
        this.content = this.container.querySelector('.modalcontent');
        this.button = this.content.querySelector('.modalclose');

        this.close = this.close.bind(this);
        this.dispatch = this.dispatch.bind(this);
        this.open = this.open.bind(this);

        if (this.container.getAttribute('id')) {
            this.openers = document.querySelectorAll('[data-open-modal="' + this.container.getAttribute('id') + '"]');

            Array.prototype.forEach.apply(this.openers, [function (el) {
                el.addEventListener('click', this.open);
            }, this]);
        }

        this.content.addEventListener('click', function (event) { event.stopPropagation(); });
        this.container.addEventListener('click', this.close);
        this.button.addEventListener('click', this.close);
    }

    ModalWindow.prototype = {
        close: function () {
            this.container.classList.remove('-open');
            this.dispatch('modal-window:close', {
                bubbles: true,
                cancelable: false,
                detail: {
                    instance: this
                }
            });
        },
        dispatch: function (name, init) {
            var newEvent;
            try {
                newEvent = new CustomEvent(name, init)
            } catch (err) {
                newEvent = document.createEvent('CustomEvent')
                newEvent.initCustomEvent(
                    name,
                    init && typeof (init.bubbles) !== 'undefined' ? init.bubbles : true,
                    init && typeof (init.cancelable) !== 'undefined' ? init.cancelable : false,
                    init ? init.detail : null
                );
            }
            this.container.dispatchEvent(newEvent);
        },
        open: function () {
            this.container.classList.add('-open');
            this.dispatch('modal-window:open', {
                bubbles: true,
                cancelable: false,
                detail: {
                    instance: this
                }
            });
        }
    }

    return ModalWindow
}));