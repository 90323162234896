; (function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('UpdateTable', ['jQuery', 'AlertMessage', 'ModalWindow'], factory);
    } else {
        // Browser globals
        root.UpdateTable = factory(jQuery, AlertMessage, ModalWindow);
    }
}(typeof self !== 'undefined' ? self : this, function ($, AlertMessage, ModalWindow) {
        'use strict';

        var instance;

        /**
         * Crea un riferimento agli elementi da aggiornare
         * @private
         * @param {string} scope Id della tabella da aggiornare
         */
        function setAjaxScope(element) {
            resetAjaxScope.apply(this);
            this.current = element;
            if (!this.current) return;
            this.$current = $(this.current);
            this.scopeId = this.$current.data('ajax-scope') ? this.$current.data('ajax-scope').replace('#','') : null;
            if (!this.scopeId) return;
            this.$ajaxScope = $('[data-ajax-table="' + this.scopeId + '"]');
            this.$ajaxContainer = $('[data-ajax-container="' + this.scopeId + '"]');
            this.$ajaxMessages = $('[data-ajax-messages="' + this.scopeId + '"]');
            this.$modal = this.$ajaxScope.find('.arsv-modal-window[data-ajax-scope="' + this.scopeId + '"], .avcb-modal-window[data-ajax-scope="' + this.scopeId + '"]');

            if (this.$modal.length) {
                var confirmationRequestMessage = this.$current.data('confirm-message');
                var onDeletedMessage = this.$current.data('ondeleted-message');
                this.modal = new ModalWindow(this.$modal[0]);
                this.$modal.find('.message').html(confirmationRequestMessage);
                this.$modal.find('[data-ajax-action="confirm"]').bind('click', this.send);
                this.$modal.find('[data-ajax-action="cancel"]').bind('click', handleCancel.bind(this));
            }
        }

        /**
         * Azzera tutti i riferimenti agli elementi da aggiornare
         * @private
         */
        function resetAjaxScope() {
            this.current = null;
            this.$current = null;
            this.$ajaxScope = null;
            this.$ajaxContainer = null;
            this.$ajaxMessages = null;
            this.$modal = null;
            this.modal = null;
            this.requestTime = null;
            this.scopeId = null;
        }

        /**
         * Mostra in UI il messaggio di errore restituito dal server
         * @private
         * @param {any} response
         */
        function handleErrors(response) {
            this.$ajaxScope.removeClass('-loading');
            this.$ajaxMessages.html(errorMessage("", response.statusText));
        }

        /**
         * Crea un alert di errore utilizzando un template ErrorMessage. Se non trova il template stampa in console
         * @private
         * @param {any} title 
         * @param {any} messageText
         * @returns {HTMLElement} Elemento alert
         */
        function errorMessage(title, messageText) {
            var messageContainer = document.getElementById('errorMessageTemplate');
            if (!messageContainer) return console.warn(messageText);
            var msg = messageContainer.firstElementChild;
            if (msg.querySelector('.title')) {
                if (title && title.length > 0) {
                    msg.querySelector('.title').innerHTML = title;
                } else {
                    msg.querySelector('.title').parentElement.removeChild(msg.querySelector('.title'));
                }
            }
            if (msg.querySelector('.content')) msg.querySelector('.content').innerHTML = messageText;

            var msg = new AlertMessage(msg);

            return msg.container;
        }

        function updateContent(data) {
            this.$ajaxScope.removeClass('-loading');
            var $data = $(data);
            var $content = $data.find('#' + this.scopeId);
            var $messages = $data.find('[data-ajax-messages="' + this.scopeId + '"]');

            var $cur = $('[data-ajax-scope="' + this.scopeId + '"][rel="next"]');
            var $next = $data.find('[data-ajax-scope="' + this.scopeId + '"][rel="next"]');
            $cur.parent().append($next);
            $cur.remove();

            if (this.$ajaxScope.hasClass('-empty') && $content.children().length != 0) {
                this.$ajaxScope.removeClass('-empty');
            }

            if (!this.$ajaxScope.hasClass('-empty') && $content.children().length == 0) {
                this.$ajaxScope.addClass('-empty');
            }

            if (this.$current.attr('rel') == 'next') {
                this.$ajaxContainer.append($content.children());
            } else {
                this.$ajaxMessages.html('');
                this.$ajaxMessages.html($messages.children());
                this.$ajaxMessages.find('[data-ui-component="alert-message"]').each(function () {
                    new AlertMessage(this);
                });
                this.$ajaxContainer.html($content.children());
            }
            resetAjaxScope.apply(this);
        }

        function handleResponse(data) {
            var update = updateContent.bind(this);

            var responseTime = new Date().getTime();
            if (responseTime - this.requestTime > this.wait) {
                update(data);
            } else {
                var timeout = this.wait - (responseTime - this.requestTime);
                window.setTimeout(function () {
                    update(data);
                }, timeout);
            }
        }

        function handleCancel() {
            this.modal.close();
            this.$modal.find('[data-ajax-action="confirm"]').unbind('click', this.send);
        }

        function sendRequest() {
            if (!this.scopeId) return;
            var action = this.$current.attr('action') || this.$current.attr('href');
            var method = this.$current.attr('method') ? this.$current.attr('method').toLowerCase() : 'get';
            this.requestTime = new Date().getTime();
            if(this.$current.attr('rel') != 'next') this.$ajaxScope.addClass('-loading');
            $[method](action, this.$current.serialize(), handleResponse.bind(this)).fail(handleErrors.bind(this));

            if (this.modal) this.modal.close();
            if (this.$modal && this.$modal.length) {
                this.$modal.find('[data-ajax-action="confirm"]').unbind('click', this.send);
            }
        }

        function UpdateTable() {
            this.wait = 1000;
            this.addRow = this.addRow.bind(this);
            this.deleteRow = this.deleteRow.bind(this);
            this.filter = this.filter.bind(this);
            this.loadMore = this.loadMore.bind(this);
            this.send = sendRequest.bind(this);
        }

        UpdateTable.prototype = {
            addRow: function (event, thisarg) {
                setAjaxScope.apply(this, [thisarg]);
                if (!this.scopeId) return;
                event.preventDefault();
                this.send();
            },
            deleteRow: function (event, thisarg) {
                setAjaxScope.apply(this, [thisarg]);
                if (!this.scopeId) return;
                event.preventDefault();
                this.modal.open();
            },
            filter: function (event, thisarg) {
                setAjaxScope.apply(this, [thisarg]);
                if (!this.scopeId) return;
                event.preventDefault();
                this.send();
            },
            loadMore: function (event, thisarg) {
                setAjaxScope.apply(this, [thisarg]);
                if (!this.scopeId) return;
                event.preventDefault();
                this.send();
            }
        }

        if (!instance) instance = new UpdateTable();

        return instance;

}));