; (function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('FileUpload', ['jQuery'], factory);
    } else {
        // Browser globals
        root.FileUpload = factory(root.jQuery);
    }
}(typeof self !== 'undefined' ? self : this, function ($) {
        'use strict';

        function FileUpload(element, options) {
            this.container = element;
            this.container.classList.remove('-no-js');
            this.input = this.container.querySelector('input[type="file"]');
            this.button = this.container.querySelector('.uploadbutton');
            this.preview = this.container.querySelector('.filename');
            this.filenametext = this.container.querySelector('.filename .text');
            this.resetbutton = this.container.querySelector('.reset');

            this.handleFocus = this.handleFocus.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.reset = this.reset.bind(this);

            this.input.addEventListener('focus', this.handleFocus);
            this.input.addEventListener('change', this.handleChange);
            this.resetbutton.addEventListener('click', this.reset);
        }

        FileUpload.prototype = {
            handleChange: function (event) {
                this.preview.classList.add('-has-file');

                var filename = '';

                if (this.input.files.length > 1) {
                    filename = this.input.files.length + ' files';
                }
                else {
                    filename = this.input.value.split(/(\\|\/)/g).pop();
                }
                this.filenametext.innerText = filename ;
            },
            handleFocus: function (event) {                
                this.button.focus();
            },
            reset: function () {
                var $input = $(this.input);
                $input.wrap('<form>').closest('form').get(0).reset();
                $input.unwrap();
                this.filenametext.innerText = '';
                this.preview.classList.remove('-has-file');
                $(this.input).change();
            }
        }

        return FileUpload;

    }));
