; (function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('LotZoom', ['jQuery'], factory);
    } else {
        // Browser globals
        root.LotZoom = factory(jQuery);
    }
}(typeof self !== 'undefined' ? self : this, function ($) {
        'use strict';

        if (typeof ($) !== 'function') return;
        if (typeof ($.zoom) !== 'function') return;

        function LotZoom(container) {
            this.container = container;
            this.$preview = $(this.container).find('.preview');
            this.$thumbs = $(this.container).find('.thumb');

            this.onclick = this.onclick.bind(this);
            this.onload = this.onload.bind(this);
            this.create = this.create.bind(this);
            this.destroy = this.destroy.bind(this);

            this.$preview.addClass('-loading');
            this.$thumbs.on('click', this.onclick);
            this.$thumbs.first().trigger('click');
        }

        LotZoom.prototype = {
            onclick: function (event) {
                var $this = $(event.target);
                if ($this.hasClass('-current')) return;
                this.$thumbs.each(function () {
                    $(this).removeClass('-current');
                });
                $this.addClass('-current');
                this.destroy();
                this.create($this.attr('data-img-src'));
            },
            onload: function (event) {
                var img = event.target;
                this.$preview.removeClass('-loading');

                if (img.naturalWidth > this.$preview.width() || img.naturalHeight > this.$preview.height()) {
                    this.$preview.zoom();
                    this.$preview.addClass('-zoom-in');
                }
            },
            create: function (src) {
                if (!src) return;
                if (!this.$preview.hasClass('-loading')) return;
                this.$preview.find('img').remove();

                src += '?t=' + new Date().getTime();

                var $img = $('<img alt="" />');
                $img.attr('src', src);

                $img.one('load', this.onload);

                this.$preview.append($img);
            },
            destroy: function () {
                this.$preview.trigger('zoom.destroy');
                this.$preview.removeClass('-zoom-in');
                this.$preview.addClass('-loading');
            }
        }

        return LotZoom;
}));