(function ($) {
    'use strict';

    if (typeof ($) !== 'function') {
        console.warn('jQuery not found: field comparation validation will not work');
        return;
    }

    $(function () {
        if (typeof ($.validator) !== 'function') {
            console.warn('jQuery.validator not found: field comparation validation will not work');
            return;
        }

        var compare = {
            equal: function (val, test) {
                return val === test;
            },
            notequal: function (val, test) {
                return val != test
            },
            greater: function (val, test) {               
                return val && test && val > test;
            },
            greaterequal: function (val, test) {
                return val && test && val >= test;
            },
            less: function (val, test) {
                return val && test && val < test;
            },
            lessequal: function (val, test) {
                return val && test && val <= test;
            }
        }

        $.validator.addMethod('compare', function (value, element, params) {
            var propName = params.propname;
            var operator = params.operator;
            var ignoreNull = params.ignorenull;

            if (ignoreNull && !element.value) {
                return true;
            }

            var fieldName = element.getAttribute('name');
            var testProp = document.querySelector('[name="' + propName + '"]');

            if (typeof (propName) === 'undefined' || propName === null) {
                console.warn('Could not validate ' + fieldName + ' against null or undefined field');
                return true;
            }

            if (typeof (operator) === 'undefined' || operator === null) {
                console.warn('Could not validate ' + fieldName + ' using a null or undefined operator');
                return true;
            }

            if (typeof (compare[operator]) !== 'function') {
                console.warn('Operator ' + operator + ' does not exist: could not validate ' + fieldName);
                return true;
            }

            if (!testProp) {
                console.warn('A field named ' + propName + ' was not found: could not validate ' + fieldName);
                return true;
            }

            return compare[operator](element.value, testProp.value);

        });

        if ($.validator.unobtrusive) {
            $.validator.unobtrusive.adapters.add('compare', ['propname', 'operator', 'ignorenull'], function (options) {
                options.rules["compare"] = {
                    propname: options.params.propname,
                    operator: options.params.operator,
                    ignorenull: options.params.ignorenull
                };
                options.messages['compare'] = options.message;
            });
        }

    });
})(jQuery);