; (function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('MobileMenu', [], factory);
    } else {
        // Browser globals
        root.MobileMenu = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
        'use strict';

        function Menu(element) {
            this.container = element;

            this.close = this.close.bind(this);
            this.ignore = this.ignore.bind(this);
            this.open = this.open.bind(this);
            this.toggle = this.toggle.bind(this);


            document.addEventListener('click', this.close);

            this.container.addEventListener('click', this.open)

            this.content = this.container.querySelector('.content');
            if (this.content) {
                this.content.addEventListener('click', this.ignore);
            }

            this.closeButtons = this.container.querySelectorAll('[data-close-menu]');
            if (this.closeButtons) {
                Array.prototype.forEach.apply(this.closeButtons, [function (item) {
                    item.addEventListener('click', this.close)
                }, this])
            }

            this.ignoreElements = this.container.querySelectorAll('[data-ignore]');
            if (this.ignoreElements) {
                Array.prototype.forEach.apply(this.ignoreElements, [function (item) {
                    item.addEventListener('click', this.ignore)
                }, this])
            }
        }

        Menu.prototype = {
            close: function () {
                this.container.classList.remove('-open');
            },
            ignore: function (event) {
                event.stopPropagation();
            },
            open: function (event) {
                var shallOpen = !this.container.classList.contains('-open');
                var others = document.querySelectorAll('[data-ui-component="mobile-menu"].-open');

                Array.prototype.forEach.apply(others, [function (other) {
                    other.classList.remove('-open');
                }])

                if (shallOpen) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.container.classList.add('-open');
                }
            },
            toggle: function () {
                this.container.classList.toggle('-open');
            }
        }

        return Menu

}));