(function ($) {
    'use strict';

    $(function () {
        if (typeof ($.validator) !== 'function') return;

        /**
         *      Tells if "element" should be marked as required, based on the value of "$testElement" and "expectedValue"
         *      @param      {HTMLElement} element Input to validate
         *      @param      {object} $testElement Test element as jQuery object
         *      @param      {string} expectedValue Expected value from "$testElement"
         *      @param      {boolean} resetField If true, validation on "element" is performed immediately
         *      @param      {boolean} isInverted If true, validation on "element" occurs if $testElement's value il different from expectedValue
         */
        function isRequired(element, $testElement, expectedValue, resetField, isInverted) {

            var result = false;

            if ($testElement.is('[type="checkbox"]')) {
                result = isInverted ? !$testElement.is(':checked') : $testElement.is(':checked');
            }
            else if ($testElement.is('[type="radio"]')) {
                var $checked = $testElement.filter(':checked');

                if ($checked.length == 0 || !$checked.val()) {
                    result = false;
                }
                else {
                    result = $checked.val().toString().toLowerCase() == expectedValue.toString().toLowerCase();
                }
            }
            else {
                result = $testElement.val().toString().toLowerCase() == expectedValue.toString().toLowerCase();
            }

            if (result) {
                element.parentElement.classList.add('-required');
            } else {
                element.parentElement.classList.remove('-required');
                element.parentElement.classList.remove('-error');

                if (resetField) {
                    $(element).valid();
                }
            }

            return result;
        }

        $.validator.addMethod('requiredif', function (value, element, params) {
            var $testProperty = $('[name="' + params.propname + '"]');

            // Client validation should be more tolerant than server validation, 
            // so if there is no test field, validation should succeed and let
            // the server handle the data
            if ($testProperty.length == 0) return true;

            // If the test field does not have the expected value, validation should
            // succeed, as the target field is not required
            if (!isRequired(element, $testProperty, params.propval, false, params.inverted)) return true;

            // Required checkboxes should be checked
            if ($(element).is('[type="checkbox"]')) return $(element).is(':checked')

            return value !== null
                && value !== ""
                && value.toLowerCase() !== 'false';

        }, '');

        $('[data-val-requiredif-propname]').each(function (i, el) {
            var $el = $(el);
            var target = $el.data('val-requiredif-propname');
            var expected = $el.data('val-requiredif-propval');
            var inverted = $el.data('val-requiredif-inverted');
            var $master = $('[name="' + target + '"]');

            $master.each(function () {
                isRequired(el, $master, expected, false, inverted);

                $(this).on('change', function () {
                    isRequired(el, $(event.target), expected, true, inverted);
                })
            })
        });

        if ($.validator.unobtrusive && $.validator.unobtrusive.adapters && typeof ($.validator.unobtrusive.adapters.add) === 'function') {

            $.validator.unobtrusive.adapters.add('requiredif', ['propname', 'propval', 'inverted'], function (options) {

                options.rules['requiredif'] = {
                    propname: options.params.propname,
                    propval: options.params.propval,
                    inverted: options.params.inverted === 'true'
                };
                options.messages['requiredif'] = options.message;
            });
        }
    });

})(jQuery);
