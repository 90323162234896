(function () {
    'use strict';

    if (document.readyState === 'interactive') {
        setup();
    } else {
        document.addEventListener('DOMContentLoaded', setup)
    }

    function loadComponent(componentName, Fn) {
        try {
            if (typeof (window.components) === 'undefined') window.components = {};
            window.components[componentName] = [];
            var elements = document.querySelectorAll('[data-ui-component="' + componentName + '"]');

            Array.prototype.forEach.apply(elements, [function (el) {
                window.components[componentName].push(new Fn(el));
            }]);
        } catch (err) {
            console.warn(err);
        }
        return;
    }

    function setup() {
        window.components = {};

        var bottomDrawer = document.querySelector('[data-ui-component="bottom-drawer"]');

        if (bottomDrawer) {
            document.addEventListener('click', function (event) {
                bottomDrawer.classList.remove('-open');
            });

            bottomDrawer.addEventListener('click', function (event) {
                event.stopPropagation();
                bottomDrawer.classList.toggle('-open');
            });
        }

        loadComponent('accordion-menu', AccordionMenu);
        //loadComponent('ajax-search', AjaxSearch);
        loadComponent('alert-message', AlertMessage);
        //loadComponent('favourite-button', FavouriteButton);
        loadComponent('file-upload', FileUpload);
        loadComponent('coward-form', CowardForm);
        //loadComponent('rich-editor', RichEditor);
        loadComponent('modal-window', ModalWindow);
        loadComponent('pagination-select', PaginationSelect);
        loadComponent('simple-carousel', SimpleCarousel);
        //loadComponent('top-drawer', TopDrawer);
        loadComponent('lot-zoom', LotZoom);
        loadComponent('mobile-menu', MobileMenu);
        loadComponent('mobile-submenu', MobileSubmenu);
        //loadComponent('list-slider', ListSlider);

        window.loadMore = UpdateTable.loadMore;
    }

})();
