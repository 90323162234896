; (function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('AccordionMenu', [], factory);
    } else {
        // Browser globals
        root.AccordionMenu = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
        'use strict';

        function Accordion(element) {
            this.container = element;
            this.items = this.container.querySelectorAll('[data-item]');
            this.toggles = this.container.querySelectorAll('[data-toggle]');

            this.expand = this.expand.bind(this);
            this.collapse = this.collapse.bind(this);

            Array.prototype.forEach.apply(this.toggles, [function (item) {
                item.addEventListener('click', this.expand);
            }, this]);
        }

        Accordion.prototype = {
            expand: function (event) {
                var collapsed = event.target.parentElement.getAttribute('aria-expanded') === 'false';
                if (!collapsed) return this.collapse();

                this.collapse();
                event.target.parentElement.setAttribute('aria-expanded', collapsed ? true : false);
            },
            collapse: function () {
                Array.prototype.forEach.apply(this.items, [function (item) {
                    item.setAttribute('aria-expanded', false);
                }]);
            }
        }

        return Accordion;
}));