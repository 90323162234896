(function ($) {
    'use strict';

    // Force required checkboxes to be true
    // http://jasonwatmore.com/post/2013/10/16/aspnet-mvc-required-checkbox-with-data-annotations

    $(function () {
        if (typeof ($.validator) !== 'function') return;

        var defaultRangeValidator = $.validator.methods.range;
        $.validator.methods.range = function (value, element, param) {
            if (!element) return function () { };

            if (element.type === 'checkbox') {
                return element.checked;
            } else {
                return defaultRangeValidator.call(this, value, element, param);
            }
        }
    })
})(jQuery);
