(function ($) {
    'use strict';

    $(function () {
        if (typeof ($.validator) !== 'function') return;

        $.validator.addMethod('maxfilesize', function (value, element, params) {

            // do not validate if input is not of type "file"
            if (element.getAttribute('type') !== 'file') {
                return true;
            }

            // do not validate if there is no file
            if (!element.files.length) {
                return true;
            }

            // do not validate multiple uploads (use a specific validator)
            if (element.files.length > 1) {
                return true;
            }

            // do not validate if there is no file size limit
            if (!params || isNaN(parseInt(params.limit))) {
                return true;
            }

            return element.files[0].size <= parseInt(params.limit);

        }, '');

        if ($.validator.unobtrusive && $.validator.unobtrusive.adapters && typeof ($.validator.unobtrusive.adapters.add) === 'function') {

            $.validator.unobtrusive.adapters.add('maxfilesize', ['limit'], function (options) {

                options.rules['maxfilesize'] = {
                    limit: options.params.limit
                };
                options.messages['maxfilesize'] = options.message;
            });
        }
    });
})(jQuery);
