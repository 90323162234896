(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('MobileSubmenu', [], factory);
    } else {
        // Browser globals
        root.MobileSubmenu = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {
    'use strict';

    function Menu(element) {
        this.container = element;
        if (!this.container) return;

        this.button = this.container.querySelector('.title');

        if (!this.button) return;

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.toggle = this.toggle.bind(this);
        this.enableSticky = this.enableSticky.bind(this);
        this.disableSticky = this.disableSticky.bind(this);
        this.watchSticky = this.watchSticky.bind(this);

        this.button.addEventListener('click', this.toggle);
        document.addEventListener('click', this.close);

        this.container.addEventListener('click', function (event) {
            event.stopPropagation();
        });

        this.watchSticky();

        window.addEventListener('resize', this.watchSticky);
    }

    Menu.prototype = {
        watchSticky: function () {
            if (this.container.clientHeight > window.innerHeight - 144) {
                return this.disableSticky();
            }

            if (window.innerWidth > 1100) {
                this.enableSticky();
            } else {
                this.disableSticky();
            }
        },
        enableSticky: function () {
            var width = this.container.clientWidth;
            this.container.style.width = width + 'px';
            this.container.style.position = 'fixed';
        },
        disableSticky: function () {
            this.container.style.width = null;
            this.container.style.position = null;
        },
        open: function (event) {
            if (event && typeof (event.stopPropagation) === 'function') {
                event.stopPropagation();
            }

            this.container.classList.add('-open');
        },
        close: function (event) {
            if (event && typeof (event.stopPropagation) === 'function') {
                event.stopPropagation();
            }
            this.container.classList.remove('-open');
        },
        toggle: function (event) {
            if (event && typeof (event.stopPropagation) === 'function') {
                event.stopPropagation();
            }

            this.container.classList.toggle('-open');
        }
    }

    return Menu

}));